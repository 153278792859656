var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-register"},[_c('Header'),_c('DialogCard',{attrs:{"title":_vm.$t('Register')}},[_c('ValidationObserver',{ref:"form",staticClass:"form app-form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t("Username"))),_c('i',{staticClass:"required"})]),_c('b-form-input',{attrs:{"placeholder":_vm.$t('Enter an username'),"readonly":_vm.loading,"autocomplete":"off"},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}}),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t("Email"))),_c('i',{staticClass:"required"})]),_c('b-form-input',{attrs:{"type":"email","placeholder":_vm.$t('Enter an email'),"readonly":_vm.loading,"autocomplete":"off"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required","vid":"password","name":_vm.$t('Password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t("Password"))),_c('i',{staticClass:"required"})]),_c('div',{staticClass:"position-relative"},[_c('b-form-input',{staticStyle:{"padding-right":"45px !important"},attrs:{"type":_vm.showPass ? 'text' : 'password',"placeholder":_vm.$t('Enter a password'),"readonly":_vm.loading,"autocomplete":"off"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('a',{staticClass:"position-absolute",staticStyle:{"top":"15px","right":"15px"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.showPass = !_vm.showPass}}},[_c('i',{class:['fa-solid', _vm.showPass ? 'fa-eye-slash' : 'fa-eye']})])],1),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t("Confirm password"))),_c('i',{staticClass:"required"})]),_c('b-form-input',{attrs:{"type":"password","placeholder":_vm.$t('Enter a password'),"readonly":_vm.loading,"autocomplete":"off"},model:{value:(_vm.formData.confirm),callback:function ($$v) {_vm.$set(_vm.formData, "confirm", $$v)},expression:"formData.confirm"}}),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"form-control-label"},[_vm._v(_vm._s(_vm.$t("Invite code")))]),_c('b-form-input',{attrs:{"placeholder":_vm.$t('Enter a invite code'),"readonly":_vm.loading || _vm.readOnlyInviteCode,"autocomplete":"off"},model:{value:(_vm.formData.inviteCode),callback:function ($$v) {_vm.$set(_vm.formData, "inviteCode", $$v)},expression:"formData.inviteCode"}}),_c('div',{staticClass:"msg-validate-error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-button',{staticClass:"font-weight-bold",attrs:{"type":"submit","variant":"primary","block":"","size":"lg","disabled":_vm.loading}},[_vm._v(" "+_vm._s(_vm.$t("Register"))+" ")])],1),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("Have you an account?"))+" "),_c('a',{staticClass:"text-primary",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name: 'Login'})}}},[_vm._v(_vm._s(_vm.$t("Login")))])])],1)],1),_c('LoadingModal',{attrs:{"show":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }