<template>
  <div class="page page-register">
    <Header/>
    <DialogCard :title="$t('Register')">
      <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label class="form-control-label">{{ $t("Username") }}<i class="required"></i></label>
            <b-form-input v-model="formData.username" :placeholder="$t('Enter an username')" :readonly="loading" autocomplete="off"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
<!--        <b-form-group>-->
<!--          <ValidationProvider rules="required" v-slot="{ errors }">-->
<!--            <label class="form-control-label">{{ $t("Phone number") }}<i class="required"></i></label>-->
<!--            <b-form-input v-model="formData.phone" :placeholder="$t('Enter a phone number')" :readonly="loading" autocomplete="off"/>-->
<!--            <div class="msg-validate-error">{{ errors[0] }}</div>-->
<!--          </ValidationProvider>-->
<!--        </b-form-group>-->
        <b-form-group>
          <ValidationProvider rules="required|email" v-slot="{ errors }">
            <label class="form-control-label">{{ $t("Email") }}<i class="required"></i></label>
            <b-form-input type="email" v-model="formData.email" :placeholder="$t('Enter an email')" :readonly="loading" autocomplete="off"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <ValidationProvider rules="required" v-slot="{ errors }" vid="password" :name="$t('Password')">
            <label class="form-control-label">{{ $t("Password") }}<i class="required"></i></label>
            <div class="position-relative">
              <b-form-input style="padding-right: 45px !important" :type="showPass ? 'text' : 'password'" v-model="formData.password" :placeholder="$t('Enter a password')" :readonly="loading" autocomplete="off"/>
              <a href="#" class="position-absolute" style="top: 15px; right: 15px" @click.prevent="showPass = !showPass"><i :class="['fa-solid', showPass ? 'fa-eye-slash' : 'fa-eye']"></i></a>
            </div>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }">
            <label class="form-control-label">{{ $t("Confirm password") }}<i class="required"></i></label>
            <b-form-input type="password" v-model="formData.confirm" :placeholder="$t('Enter a password')" :readonly="loading" autocomplete="off"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <ValidationProvider v-slot="{ errors }">
            <label class="form-control-label">{{ $t("Invite code") }}</label>
            <b-form-input v-model="formData.inviteCode" :placeholder="$t('Enter a invite code')" :readonly="loading || readOnlyInviteCode" autocomplete="off"/>
            <div class="msg-validate-error">{{ errors[0] }}</div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group>
          <b-button type="submit" variant="primary" block size="lg" class="font-weight-bold" :disabled="loading">
            {{$t("Register")}}
          </b-button>
        </b-form-group>
        <div class="text-center">
          {{$t("Have you an account?")}} <a href="#" class="text-primary" @click.prevent="$router.push({name: 'Login'})">{{$t("Login")}}</a>
        </div>
      </ValidationObserver>
    </DialogCard>
    <LoadingModal :show="loading"/>
  </div>
</template>

<script>
import Header from "@/components/headers/Header";
import DialogCard from "@/components/cards/DialogCard";
import dialogHelper from "@/helpers/dialogHelper";
import authenticateService from "@/services/authenticateService";

export default {
  name: "Register",
  components: {Header, DialogCard},
  data() {
    return {
      loading: false,
      showPass: false,
      readOnlyInviteCode: false,
      formData: {
        username: "",
        email: "",
        //phone: "",
        password: "",
        confirm: "",
        inviteCode: ""
      }
    }
  },
  created() {
    const inviteCode = this.$route.query.inviteCode || "";
    this.formData.inviteCode = inviteCode;
    this.readOnlyInviteCode = inviteCode.length > 0;
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await authenticateService.register(this.formData, await this.$recaptcha('register'))
      this.loading = false;

      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      await dialogHelper.alert(response.message, "success");
      await this.$router.push({name: "Login"})
    }
  }
}
</script>
